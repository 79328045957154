import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/Products';
import Faq from './pages/Faq';
import Wholesale from './pages/Wholesale';
import Order from './pages/Order';
import { Router, Link } from '@reach/router';

function App() {
  return (
    <div className="flex flex-col [min-height:100vh]">
      <Nav />
      <div className="grow">
        <Router>
          <Home path="/" />
          <Products path="/products" />
          <Faq path="/faq" />
          <Wholesale path="/wholesale" />
          <Order path="/order" />
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
