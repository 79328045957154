function Footer() {
  return (
    <div className="bg-stone-800 z-10 relative w-full [min-height:200px]">
      <div className="container mx-auto sm:flex">
        <div className="pt-5 px-5 sm:px-0 w-full sm:w-1/3 text-stone-400">
          <p>
            <strong>Kiibo, LLC</strong> is a company based in Bellevue, Washington. We design showpiece cardboard products with an aim to inspire and foster connection.
          </p>
        </div>
        <div className="pt-5 hidden sm:block sm:w-1/3 text-stone-400">
        </div>
        <div className="pt-5 px-5 sm:px-0 w-full sm:w-1/3 text-stone-400 sm:text-right">
          <p>
            For general inquiries, please e-mail <a className="underline text-stone-200" href="mailto:hello@kiibo.com">hello@kiibo.com</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
