import React, { useState } from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import SeattleImg from '../img/seattle.jpg';
import CurrencyFormat from 'react-currency-format';

function Order() {
  const [submitted, setSubmitted] = useState(false);

  const WholesaleSchema = Yup.object().shape({
    store: Yup.string()
      .required('Store name is required'),
    email: Yup.string()
      .email('Invalid email address format')
      .required('E-mail is required'),
    phone: Yup.string()
      .required('Phone number is required'),
    address: Yup.string()
      .required('Delivery address is required'),
    seattle_qty: Yup.number()
      .integer('Invalid number')
      .min(0, 'Order minimum is zero')
      .max(100, 'Order maximum is 100')
  });

  const FormField = ({ id, placeholder, errors, touched, className }) => (
    <>
      <Field className={`bg-gray-200 mb-1 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-400 ${touched[id] && errors[id] && 'border-red-500'} ${className}`} placeholder={placeholder} id={id} name={id} />
      <div className="[min-height:20px] text-sm mb-2">
        {touched[id] && errors[id]}
      </div>
    </>
  )

  const handleSubmit = async ({ seattle_qty, store, email, phone, address }, { setSubmitting }) => {
    await fetch("https://docs.google.com/forms/u/0/d/e/1FAIpQLSed7qwiPrSiXtzijPRlL_Vvtf7TPJmJ8swYrhJ4ZmEpWwkAsA/formResponse", {
      "credentials": "include",
      "headers": {
        "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:96.0) Gecko/20100101 Firefox/96.0",
        "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8",
        "Accept-Language": "en,en-US;q=0.5",
        "Content-Type": "application/x-www-form-urlencoded",
        "Upgrade-Insecure-Requests": "1",
        "Sec-Fetch-Dest": "document",
        "Sec-Fetch-Mode": "navigate",
        "Sec-Fetch-Site": "same-origin",
        "Sec-Fetch-User": "?1",
        "Pragma": "no-cache",
        "Cache-Control": "no-cache"
      },
      "referrer": "https://docs.google.com/forms/d/e/1FAIpQLSed7qwiPrSiXtzijPRlL_Vvtf7TPJmJ8swYrhJ4ZmEpWwkAsA/viewform?fbzx=4017359713151746675",
      "body": `entry.730865883=seattle_${seattle_qty}&entry.1553901900=${store}&entry.1547978560=${email}&entry.664533901=${phone}&entry.1581363936=${address}`,
      "method": "POST",
      "mode": "no-cors"
    });
    setSubmitting(false);
    setSubmitted(true);
  }

  const calcShipping = (values) => values['seattle_qty'] < 1 || values['seattle_qty'] >= 10 ? 0 : 10;

  return (
    <div className="container text-stone-200 mx-auto p-10">
      <div className="text-center">
        <h2 className="text-2xl mx-auto text-gray-200 border-b-4 pb-1 mb-5 border-orange-400 inline-block">Order Form</h2>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
        {submitted && (
          <p>Thank you for your order! We'll process it shortly and get back to you.</p>
        )}
        {!submitted && (<>
          <p className="mb-5">Please fill out this form to order our products! If you need assistance or have any questions, please e-mail hello@kiibo.com.</p>
          <p className="mb-10">Orders of ten or more units qualify for <strong>free shipping!</strong></p>
          <Formik
            initialValues={{
              seattle_qty: 0,
              store: "",
              email: "",
              phone: "",
              address: "",
            }}
            validationSchema={WholesaleSchema}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({touched, errors, isSubmitting, values}) =>
              isSubmitting ?
                (<div className="flex justify-center"><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-400" xmlns="http://www.w3.org/2000/svg"
                                                           fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg></div>) :
                (<Form className="mx-auto text-center">
                  <table className="mb-5">
                    <tr>
                      <th className="w-1/4">Product</th>
                      <th className="w-1/4">Unit Price</th>
                      <th className="w-1/4">RRP/SRP</th>
                      <th className="w-1/4">Order Qty.</th>
                    </tr>
                    <tr>
                      <td className="pt-3">
                        <div>
                          <img width={250} height={250} src={SeattleImg} className="rounded-t-md" />
                          <span className="inline-block w-full bg-stone-800 rounded-b-md">City of Seattle</span>
                        </div>
                      </td>
                      <td>$9.00</td>
                      <td>$18.00</td>
                      <td>
                        <FormField className='w-24 mt-6' id='seattle_qty' placeholder='' errors={errors} touched={touched} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-right font-bold">Subtotal:</td>
                      <td className="text-right pr-10"><CurrencyFormat value={values['seattle_qty'] * 9} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-right font-bold">Shipping & Handling:</td>
                      <td className="text-right pr-10"><CurrencyFormat value={calcShipping(values)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-right font-bold">Total Due:</td>
                      <td className="text-right pr-10"><CurrencyFormat value={(values['seattle_qty'] * 9) + calcShipping(values)} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  </table>
                  <FormField id='store' placeholder='Store Name*' errors={errors} touched={touched} />
                  <FormField id='email' placeholder='E-mail Address*' errors={errors} touched={touched} />
                  <FormField id='phone' placeholder='Phone Number*' errors={errors} touched={touched} />
                  <FormField id='address' placeholder='Delivery Address*' errors={errors} touched={touched} />
                  <p className="mb-10 text-sm text-left">
                    <strong>Terms & Conditions</strong>
                    <ol className="list-decimal">
                      <li>Payment terms are NET-30</li>
                      <li>No minimum order quantity, shipping is free for orders of 10 units or more</li>
                      <li>Goods found to be damaged upon receipt will be replaced free of charge</li>
                      <li>Undamaged goods in unused and original condition may be returned for a refund, less a 15% restocking fee, within 30 days of receipt</li>
                    </ol>
                  </p>
                  <p>You will receive an invoice via E-mail once we process your order.</p>
                  <button type='submit' className='bg-stone-700 hover:bg-stone-600 text-white font-bold py-2 px-4 rounded mt-7'>Submit Order</button>
                </Form>)
            }
          </Formik>
        </>)}
      </div>
    </div>
  )
}

export default Order;
