import React, { useState } from 'react';
import { Link } from '@reach/router';
import KiiboLogo from '../img/kiibo.png';

function Nav() {
  const [navPath, setNavPath] = useState(window.location.pathname);
  const [menu, setMenu] = useState(false);

  const RenderLink = ({ path, text }) => (
    // <Link to={path}
    //       className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-orange-200 md:p-0 dark:text-white"
    //       aria-current="page">{text}</Link>
    <Link to={path}
          onClick={() => { setMenu(false); setNavPath(path); }}
          className={`${navPath.replace('/','') === path.replace('/', '') ? 'md:text-orange-200' : ''} block py-2 pr-4 pl-3 text-gray-300 border-b border-gray-100 hover:bg-stone-500 md:hover:bg-transparent md:border-0 md:hover:text-orange-200 md:p-0`}>
      <span className='p-2 [background-color:#7c737c90] rounded-md'>{text}</span>
    </Link>
  )

  return (
    <div className="container relative mx-auto z-20">
    <nav className="border-gray-200 px-2 sm:px-4 pb-2.5 rounded dark:bg-gray-800">
      <div className="flex flex-wrap justify-between md:justify-start items-center">
        <a href="#" className="flex">
          <div className="bg-white p-5 rounded-b-lg">
            <img src={KiiboLogo} className="[height:35px]" />
          </div>
        </a>
        <button type="button"
                id="menu"
                onClick={() => setMenu(!menu)}
                className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden hover:bg-stone-600 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"></path>
          </svg>
          <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
          </svg>
        </button>
        <div className={`${!menu ? 'hidden' : 'block fixed top-20 bg-stone-600'} w-full md:block md:relative md:top-0 md:bg-transparent md:w-auto md:ml-10`}>
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
            <li>
              <RenderLink path="/" text="Home" />
            </li>
            <li>
              <RenderLink path="/products" text="Products" />
            </li>
            <li>
              <RenderLink path="/faq" text="FAQ" />
            </li>
            <li>
              <RenderLink path="/wholesale" text="Wholesale" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </div>
  )
}

export default Nav;
