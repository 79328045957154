import { useState } from 'react';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

function Wholesale() {
  const [submitted, setSubmitted] = useState(false);

  const WholesaleSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address format')
      .required('E-mail is required'),
    store: Yup.string()
      .required('Store name is required'),
    website: Yup.string()
      .required('Website is required'),
    ubi: Yup.string()
      .required('UBI is required'),
  });

  const FormField = ({ id, placeholder, errors, touched }) => (
    <>
      <Field className={`bg-gray-200 mb-1 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-orange-400 ${touched[id] && errors[id] && 'border-red-500'}`} placeholder={placeholder} id={id} name={id} />
      <div className="[min-height:20px] text-sm mb-2">
        {touched[id] && errors[id]}
      </div>
    </>
  )

  const handleSubmit = async ({ name, email, store, website, ubi }, { setSubmitting }) => {
    await fetch("https://docs.google.com/forms/u/0/d/e/1FAIpQLSeg8c21s3QD_zJnaQnzkPggQg9FaQ2hSc0TLmS858GKZ1kysw/formResponse", {
      "credentials": "include",
      "headers": {
        "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:96.0) Gecko/20100101 Firefox/96.0",
        "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,*/*;q=0.8",
        "Accept-Language": "en,en-US;q=0.5",
        "Content-Type": "application/x-www-form-urlencoded",
        "Upgrade-Insecure-Requests": "1",
        "Sec-Fetch-Dest": "document",
        "Sec-Fetch-Mode": "navigate",
        "Sec-Fetch-Site": "same-origin",
        "Sec-Fetch-User": "?1",
        "Pragma": "no-cache",
        "Cache-Control": "no-cache"
      },
      "referrer": "https://docs.google.com/forms/d/e/1FAIpQLSeg8c21s3QD_zJnaQnzkPggQg9FaQ2hSc0TLmS858GKZ1kysw/viewform?fbzx=-6595085865375176537",
      "body": `entry.126068071=${name}&entry.1171611911=${email}&entry.1826592048=${store}&entry.655774074=${website}&entry.663854516=${ubi}`,
      "method": "POST",
      "mode": "no-cors"
    });
    setSubmitting(false);
    setSubmitted(true);
  }

  return (
    <div className="container text-stone-200 mx-auto p-10">
      <div className="text-center">
        <h2 className="text-2xl mx-auto text-gray-200 border-b-4 pb-1 mb-5 border-orange-400 inline-block">Wholesale</h2>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
        <p className="mb-10">
          {!submitted && 'Interested in selling our products in your store? Please fill out this form and we\'ll get back to you.'}
          {submitted && 'Your submission has been recorded. We\'ll respond shortly. Thank you!'}
        </p>
        <Formik
          initialValues={{
            name: "",
            email: "",
            store: "",
            website: "",
            ubi: "",
          }}
          validationSchema={WholesaleSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({touched, errors, isSubmitting, values}) =>
            isSubmitting ?
              (<div className="flex justify-center"><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-400" xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg></div>) :
              (!submitted && <Form className="mx-auto text-center">
                <FormField id='name' placeholder='Name*' errors={errors} touched={touched} />
                <FormField id='email' placeholder='E-mail Address*' errors={errors} touched={touched} />
                <FormField id='store' placeholder='Store Name*' errors={errors} touched={touched} />
                <FormField id='website' placeholder='Website URL*' errors={errors} touched={touched} />
                <FormField id='ubi' placeholder='UBI/resale number*' errors={errors} touched={touched} />
                <button type='submit' className='bg-stone-700 hover:bg-stone-600 text-white font-bold py-2 px-4 rounded mt-7'>Submit Form</button>
              </Form>)
          }
        </Formik>
      </div>
    </div>
  )
}

export default Wholesale;
