import React from 'react';
import HeroImg from '../img/staged.jpg';

function Home() {
  return (
    <div className="z-0 absolute overflow-hidden w-full [height:100%]" style={{ marginTop: '-90px' }}>
      <div className="bg-cover bg-bottom [height:101%] [width:101%]" style={{ marginTop: '-20px', backgroundImage: `url(${HeroImg})` }}></div>
    </div>
  )
}

export default Home;
