import { Link } from "@reach/router"

function Faq() {
  return (
    <div className="container text-stone-200 mx-auto p-10">
      <div className="text-center">
        <h2 className="text-2xl mx-auto text-gray-200 border-b-4 pb-1 mb-5 border-orange-400 inline-block">FAQ</h2>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
      <ul>
        <li className="pb-2 font-bold">
          Q: What is the size of a Kiibo product?
        </li>
        <li className="pb-10">
          A: When packaged, our products measure 5⅝ 𝘅 4½ 𝘅 ³/₁₆ inches. Once the product is built, the base measures about 3 𝘅 3 inches.
        </li>
        <li className="pb-2 font-bold">
          Q: Can I buy online?
        </li>
        <li className="pb-10">
          A: At the moment, you can only buy our kits at participating retailers. Please drop us a line if you're having trouble locating our products!
        </li>
        <li className="pb-2 font-bold">
          Q: How do I become a retailer for Kiibo?
        </li>
        <li className="pb-10">
          A: Please fill out our <Link className="underline hover:text-stone-100" to="/wholesale">retailer form</Link>.
        </li>
      </ul>

      <p className="pt-5">Didn't see your question answered? Email hello@kiibo.com with any of your questions. Cheers!</p>
      </div>
    </div>
  )
}

export default Faq;
