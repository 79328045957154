import React from 'react';
import XmasImg from '../img/xmas.jpg';
import SeattleImg from '../img/seattle.jpg';

function Products() {
  return (
    <div className="container mx-auto p-10">
      <div className="text-center">
        <h2 className="text-2xl mx-auto text-gray-200 border-b-4 pb-1 mb-5 border-orange-400 inline-block">Products</h2>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="mr-0 mb-5 rounded-md bg-white p-5 w-full sm:w-1/3 md:w-1/5 sm:mr-5">
          <img width={250} height={250} src={SeattleImg} className="[width:250px] [height:250px] mx-auto object-cover" />
          <p className="w-full text-center font-bold">City of Seattle</p>
        </div>
        <div className="mr-0 mb-5 rounded-md bg-white p-5 w-full sm:w-1/3 md:w-1/5 sm:mr-5">
          <img src={XmasImg} className="[width:250px] [height:250px] mx-auto object-cover" />
          <p className="w-full text-center font-bold">Holiday Spirit <br/><span className="text-red-400 font-normal">(retired)</span></p>
        </div>
      </div>
    </div>
  )
}

export default Products;
